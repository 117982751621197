<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :form-fields="formFields"
    authority="Users_Payment"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import { getSubscriptionTypes } from '@/auth/utils'

import storeModule from '../invoiceStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      paymentMethodOptions: [],
      userOptions: [],
      subscriptionOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'payments/invoices',
        endpoint: 'payments/invoices',
        route: 'payments/invoices',
        title: {
          single: this.$i18n.t('Payment'),
          plural: this.$i18n.t('Payments'),
        },
      }
    },
    subscriptionTypeOptions() {
      return getSubscriptionTypes()
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'user.title', label: this.$t('User'), sortable: true },
        {
          key: 'subscriptionPackage.title',
          label: this.$t('Subscription'),
          sortable: true,
        },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        {
          key: 'paymentMethod.title',
          label: this.$t('Payment Method'),
          sortable: false,
        },
        {
          key: 'transactionId',
          label: this.$t('Transaction ID'),
          sortable: false,
        },
        { key: 'paymentDate', label: this.$t('Payment Date'), sortable: true },
      ]
    },

    formattedColumns() {
      return [{ key: 'paymentDate', type: 'datetime' }]
    },
    formFields() {
      return [
        {
          id: 'userId',
          type: 'select',
          label: this.$t('User'),
          options: this.userOptions,
          required: true,
        },
        {
          id: 'subscriptionPackage',
          object: true,
          type: 'select',
          label: this.$t('Subscription'),
          options: this.subscriptionOptions,
          required: true,
        },
        {
          id: 'amount',
          type: 'text',
          label: this.$t('Paid Amount'),
          required: true,
          rules: 'positive',
        },
        {
          id: 'paymentMethod',
          object: true,
          type: 'select',
          label: this.$t('Payment Method'),
          options: this.paymentMethodOptions,
          required: true,
        },
        {
          id: 'paymentDate',
          type: 'datetime',
          label: this.$t('Payment Date'),
          required: true,
          maxDate: new Date(),
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: false,
        },
        {
          id: 'comments',
          type: 'quill',
          label: this.$t('comments'),
          required: false,
        },
      ]
    },

    filters() {
      return [
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '1',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '0',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchPaymentMethods').then((response) => {
      this.paymentMethodOptions = response.data
    })
    this.$store.dispatch('app-common/fetchUsers').then((response) => {
      this.userOptions = response.data
    })
    this.$store
      .dispatch('app-common/fetchPlainSubscriptions')
      .then((response) => {
        this.subscriptionOptions = response.data
      })
  },
}
</script>
